"use client"
import Image from 'next/image';
import Link from 'next/link';
import { useRef, useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';

const TopicsSeaction = () => {
    const sliderRef = useRef(null);
    const [CategoriesBlockDate, setCategoriesBlockData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await fetch(process.env.NEXT_PUBLIC_API_URL + 'api/categories-details', {
                    next: { revalidate: 0 }, // Optional: Revalidate every 60 seconds
                });

                if (!res.ok) {
                    const errorText = await res.text();
                    console.error('Failed to fetch post data:', res.status, res.statusText);
                    console.error('Response body:', errorText);
                    throw new Error('Failed to fetch post data');
                }

                const data = await res.json();
                setCategoriesBlockData(data);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        }

        fetchData();
    }, []);

    const scrollLeft = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollBy({ left: -300, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollBy({ left: 300, behavior: 'smooth' });
        }
    };

    return (
        <article className='my-6'>
            <div className="md:h-10 h-10"></div>
            <div>
                <div className=" relative text-neutral-900 mb-6">
                    <div className="max-w-2xl w-full">
                        <h2 className=" font-bold text-2xl md:text-3xl lg:text-4xl">
                            Top trending Categories
                        </h2>
                        <span className=" mt-2 font-normal block text-neutral-500  max-w-4xl md:mt-3 text-sm sm:text-base md:text-lg">
                            Discover {CategoriesBlockDate.length} Categories 👑
                        </span>
                    </div>
                </div>
            </div>
            <div className="relative">
                <button aria-label="Action" onClick={scrollLeft} className="bg-gray-200 text-xl hover:bg-teal-600 border-1 border-gray-400 p-2 shadow-2xl rounded-full absolute top-[80px] transform -translate-y-1/2 z-10 left-0 text-teal-600 hover:text-gray-300">
                    <FaChevronLeft />
                </button>
                <div
                    ref={sliderRef}
                    className=" relative flex snap-x snap-mandatory overflow-x-auto overflow-y-hidden "
                    style={{ scrollBehavior: 'smooth' }}
                >
                    {CategoriesBlockDate.map((category, i) => (
                        <div key={i} className=" shrink-0 snap-start px-2 lg:px-4">
                            <Link href={"/category/" + category.slug}>
                                <div className="flex flex-col">
                                    <div className="h-[150px] w-[150px] bg-indigo-400 relative flex-shrink-0 overflow-hidden rounded-3xl ring-2 ring-white">
                                        <Image
                                            alt={category.name}
                                            src={process.env.NEXT_PUBLIC_IMG_URL + category.icon}
                                            width={100}
                                            height={100}
                                            loading="lazy"
                                            className="h-[150px] w-[150px] object-cover rounded-2xl"
                                        />

                                    </div>
                                    <div className="mt-3 flex items-center sm:mt-5">
                                        <div className="ms-2 sm:ms-4">
                                            <h3 className="text-base font-semibold text-gray-900">
                                                <span className="line-clamp-1">{category.name}</span>
                                            </h3>
                                            <span className="block text-xs text-gray-600 sm:text-sm">
                                                {category?.totalPosts || 0} Articles
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}

                </div>
                <button aria-label="Action" onClick={scrollRight} className="bg-gray-200 text-xl hover:bg-teal-600 border-1 border-gray-400 p-2 shadow-2xl  absolute right-0 top-[80px] transform -translate-y-1/2 z-10 rounded-full   text-teal-600 hover:text-gray-300 ">
                    <FaChevronRight />
                </button>
            </div>
        </article>
    )
}
export default TopicsSeaction

// async function GetcategoriesFacherData() {
//     const res = await fetch(process.env.NEXT_PUBLIC_API_URL + `api/categories-details`, {
//         next: { revalidate: 0 }, // Optional: Revalidate every 60 seconds
//     });

//     if (!res.ok) {
//         const errorText = await res.text(); // Read the response as text
//         console.error('Failed to fetch post data:', res.status, res.statusText);
//         console.error('Response body:', errorText); // Log the response body
//         throw new Error('Failed to fetch post data');
//     }

//     try {
//         const data = await res.json();
//         return data;
//     } catch (error) {
//         console.error('Failed to parse JSON:', error);
//         throw new Error('Failed to parse JSON');
//     }
// }