"use client";
import { useEffect, useRef } from 'react';
import Script from 'next/script';

function Ads({ adData }) {
    const adRef = useRef();

    useEffect(() => {
        // Define loadAds function inside the useEffect
        const loadAds = () => {
            // Ensure adsbygoogle is defined and check if ad is already in place
            if (adRef.current && adData.show) {
                // Check if there are already ads in the ins element
                const adSlot = adRef.current.querySelector('.adsbygoogle');

                // If the adSlot is present and doesn't have an ad yet
                if (adSlot && adSlot.innerHTML.trim() === "") {
                    if (window.adsbygoogle) {
                        (window.adsbygoogle = window.adsbygoogle || []).push({});
                    } else {
                        console.error("adsbygoogle is not defined.");
                    }
                } else {
                    console.log("Ad already loaded in this slot.");
                }
            }
        };

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    loadAds();
                    observer.disconnect(); // Stop observing once loaded
                }
            },
            { threshold: 0.1 }
        );

        if (adRef.current) {
            observer.observe(adRef.current);
        }

        return () => {
            if (adRef.current) {
                observer.unobserve(adRef.current);
            }
        };
    }, [adData]);

    return (
        <div ref={adRef}>
            {adData.show ? (
                <>
                    <Script
                        async
                        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7022055454013295"
                        crossOrigin="anonymous"
                        onLoad={() => {
                            // Initialize ads only after the script has loaded
                            const loadAds = () => {
                                if (adRef.current && adData.show) {
                                    const adSlot = adRef.current.querySelector('.adsbygoogle');

                                    if (adSlot && adSlot.innerHTML.trim() === "") {
                                        if (window.adsbygoogle) {
                                            (window.adsbygoogle = window.adsbygoogle || []).push({});
                                        } else {
                                            console.error("adsbygoogle is not defined.");
                                        }
                                    } else {
                                        console.log("Ad already loaded in this slot.");
                                    }
                                }
                            };

                            loadAds(); // Call the local loadAds function here
                        }}
                    />
                    {/* Ad slot container */}

                </>
            ) : null}
        </div>
    );
}

export default Ads;