"use client"
import Image from 'next/image'
import Link from 'next/link';
import React, { useEffect, useState } from 'react'
import NextTopLoader from 'nextjs-toploader';


function Header({ headerData }) {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []); // Empty dependency array ensures this runs once on mount
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const logodata = JSON.parse(headerData.logo)
    const menu = JSON.parse(headerData.menu)
    const mobilemenu = JSON.parse(headerData.mobilemenu)
    const [search, setSearch] = useState('')
    return (
        <div>

            <header className='w-full fixed z-20  px-4 sm:px-6 md:px-8 lg:px-32 flex justify-between items-center bg-white/95 backdrop-blur-sm border-b border-gray-300 shadow-sm'>
                <div className="flex items-center">
                    <Link title={logodata.title} href={logodata.link}>
                        <Image
                            alt={logodata.Alt}
                            src={process.env.NEXT_PUBLIC_IMG_URL + logodata.image}
                            priority
                            width={logodata.w}
                            height={logodata.h}
                            className='p-4 object-cover'
                        /></Link>
                    <div className="hidden lg:block pl-6 w-64 max-w-xs xl:w-80">
                        <div className="relative">
                            <input
                                type="text"
                                className="block border border-gray-200 bg-white/10 rounded-full text-sm font-normal h-[42px] pl-4 py-3 pr-5 w-full"
                                placeholder="Type to search..."
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <Link name={'search'} title='search'
                                href={{ pathname: '/blog-search', query: { keyword: search } }}
                                passHref
                            >
                                <div className="absolute inset-y-0 right-0 flex items-center justify-center rounded-full pr-3 text-neutral-500 cursor-pointer">
                                    <svg className="h-5 w-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M22 22L20 20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <NextTopLoader
                    color="#3399ff"
                    initialPosition={0.08}
                    crawlSpeed={200}
                    height={3}
                    crawl={true}
                    showSpinner={true}
                    easing="ease"
                    speed={200}
                    template='<div class="bar" role="bar">
                    <div class="peg">
                    </div>
                    </div>'
                    zIndex={1600}
                    showAtBottom={false}
                />
                <div className="flex items-center space-x-4 lg:space-x-6">
                    <nav className="hidden lg:block">

                        <ul className='flex gap-6 text-md font-normal text-gray-600'>
                            {menu.map((data, i) => (
                                <li key={i}><Link title={data.name} href={data.link}>{data.name}</Link></li>
                            ))}
                        </ul>
                    </nav>
                    <button disabled className="hidden lg:block border-blue-700 border-2 text-blue-700 p-2 px-4 rounded-full bg-white">
                        Join Newsletter
                    </button>
                    <button
                        className="lg:hidden p-2" aria-label="Open menu"
                        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                    >
                        <svg className="h-6 w-6 text-gray-600" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 6h16M4 12h16M4 18h16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </button>
                </div>
            </header>
            {isMobileMenuOpen && (
                <div className="w-screen h-screen  relative z-30 bg-black/50 overflow-ellipsis backdrop:blur-md">
                    <div className="fixed z-40 inset-0 bg-gray-100 top-4 bottom-4 p-6 left-1/2 transform -translate-x-1/2 rounded-xl w-[300px] shadow-lg lg:hidden">
                        <div className="flex flex-col">
                            <button
                                aria-label="Close"
                                className="absolute top-4 right-4"
                                onClick={() => setIsMobileMenuOpen(false)}
                            >
                                <svg className="h-6 w-6 text-gray-600" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 18L18 6M6 6l12 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </button>

                            <div className="flex items-center justify-center mb-4">
                                <Link title={logodata.title} href={logodata.link}>
                                    <Image
                                        alt={logodata.Alt}
                                        src={process.env.NEXT_PUBLIC_IMG_URL + logodata.image}
                                        priority
                                        width={logodata.w}
                                        height={logodata.h}
                                        className='p-4 object-cover'
                                    /></Link>
                            </div>

                            <div className="relative w-full mb-5">
                                <input
                                    type="text"
                                    className="block border border-gray-300 w-full bg-white rounded-full text-sm font-normal h-[42px] pl-4 pr-10"
                                    placeholder="Type to search..."
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <Link href={{ pathname: '/blog-search', query: { keyword: search } }} passHref>
                                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-neutral-500 cursor-pointer">
                                        <svg className="h-5 w-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M22 22L20 20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </div>
                                </Link>
                            </div>

                            <nav>
                                <ul className='flex flex-col items-center space-y-4 text-md font-normal text-gray-600'>
                                    {mobilemenu.map((data, i) => (
                                        <li className='border-b-1 border-gray-900 text-center font-bold text-xl text-gray-800 w-full pb-2' key={i}>
                                            <Link title={data.name} href={data.link}>{data.name}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </nav>

                            <button
                                disabled
                                className='mt-4 border-blue-500 border-2 text-blue-500 p-2 h-12 font-semibold w-full rounded-lg'
                            >
                                Join Newsletter
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Header
